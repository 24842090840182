<template>
  <BaseDialog :dialog="value" :title="$t('content.invoice_title_file_list')" width="50%" @close="onClose">
    <v-list v-if="items.length" nav class="px-0">
      <v-list-item v-for="item in items" :key="item.id" class="custom-list mb-4">
        <v-list-item-content>
          <v-list-item-title class="title" v-text="getName(item.file_name)" />
        </v-list-item-content>
        <v-list-item-action>
          <div class="d-flex">
            <BaseActionButton :tooltipsText="$t('content.view')" @click="onShowFile(item)">
              <SvgIcon icon="icon-eye" />
            </BaseActionButton>
            <BaseActionButton :tooltipsText="$t('content.download')" @click="onDownload(item)">
              <SvgIcon icon="icon-download" width="20px" height="20px" />
            </BaseActionButton>
            <BaseActionButton :tooltipsText="$t('content.share')" @click="onShareInvoice(item)">
              <SvgIcon icon="icon-share" width="20px" height="20px" />
            </BaseActionButton>
          </div>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <div v-else>
      <div class="d-block text-center">
        <SvgIcon icon="no-data-icon" />
      </div>
      <div class="d-block text-center mt-10 message">
        No Invoice Files
      </div>
    </div>
  </BaseDialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'InvoiceDialogFileList',
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    invoiceNumberDialog: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      doc: 'File/doc',
    }),
  },
  methods: {
    getName(url) {
      return decodeURIComponent(url.substring(url.lastIndexOf('/') + 1));
    },
    onShowFile(item) {
      this.$emit('showFile', item);
    },
    onDownload(item) {
      let name = this.getName(item.file_url);
      this.$store
        .dispatch('File/getData', {
          data: { name, url: item.file_url },
          type: 'doc',
        })
        .then(() => {
          const link = document.createElement('a');

          link.href = this.doc.blob;
          link.download = name;

          document.body.appendChild(link);

          link.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window,
            }),
          );

          document.body.removeChild(link);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onShareInvoice(item) {
      this.$emit('shareInvoice', {
        id: item.id,
        contentTypeId: item.content_type_id,
        name: this.getName(item.file_url),
      });
    },
    onClose() {
      this.$emit('close', false);
    },
  },
};
</script>
