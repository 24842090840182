<template>
  <BaseDialog :dialog="value" width="500" @close="onCloseDialog">
    <div class="d-flex justify-center align-center flex-column mb-6">
      <img height="150" class="mx-auto mb-6" :src="require(`../../assets/images/wire-transfer-info.png`)" />
      <h2 class="font-weight-black t-navy mb-3">
        {{ $t('content.invoice_title_transfer') }}
      </h2>
      <h4 class="t-navy">
        {{ $t('content.invoice_subtitle_transfer') }}
      </h4>
      <v-btn large depressed x-large class="indigo white--text mt-4" v-on="$listeners">
        OK
      </v-btn>
    </div>
  </BaseDialog>
</template>

<script>
export default {
  name: 'InvoiceTransferInfo',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onCloseDialog() {
      this.$emit('input', false);
    },
  },
};
</script>
