<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" class="d-flex align-center justify-end" order-md="2">
        <v-btn small depressed class="indigo white--text px-4" :loading="loading" @click="onExportToExcel">
          {{ $t('content.export_excel') }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" order-md="1">
        <v-tabs height="35px" v-model="tab" :grow="$vuetify.breakpoint.smAndDown">
          <v-tab class="custom-tabs" v-for="tabs in tabsItems" :key="tabs">
            {{ tabs }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-tabs-items v-model="tab">
      <v-tab-item class="pt-4">
        <InvoiceList :initial-status="statusUnpaid" />
      </v-tab-item>
      <v-tab-item class="pt-4">
        <InvoiceList :initial-status="statusPaid" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { utilityMixin, downloadMixin } from '@/mixins';
import InvoiceList from '@/components/invoice/InvoiceList';

export default {
  name: 'Invoice',
  mixins: [utilityMixin, downloadMixin],
  components: {
    InvoiceList,
  },
  data() {
    return {
      loading: false,
      statusUnpaid: 'No Payments Allocated',
      statusPaid: 'Fully Paid',
      tab: 0,
      tabsItems: [this.$t('content.unpaid'), this.$t('content.paid')],
    };
  },
  computed: {},
  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      handler(query) {
        this.tab = query.tab === 'paid' ? 1 : 0;
      },
    },
    tab: {
      handler(val) {
        this.$router.push({ query: { tab: val === 0 ? 'unpaid' : 'paid' } }).catch(() => {});
      },
    },
  },
  methods: {
    onExportToExcel() {
      const data = this.tab
        ? '/invoicing_workbench/api/invoices/paid/export_excel/'
        : '/invoicing_workbench/api/invoices/unpaid/export_excel/';
      const params = this.tab ? this.paramsStatusPaid : this.paramsStatusUnpaid;
      const name = `IDFL ${this.tab ? 'Paid' : 'Unpaid'} Invoice ${this.utilityMixin_formatSDateTime(new Date())}.xlsx`;
      this.loading = true;
      this.$store
        .dispatch('File/getData', { data, params })
        .then(blob => {
          this.downloadMixin_download(blob, { name });
        })
        .finally(() => (this.loading = false));
    },
  },
  created() {},
};
</script>
