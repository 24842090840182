<template>
  <div>
    <BaseDialog :dialog="dialog" :title="invoice.name" :subTitle="$t('content.share_in')" width="50%" @close="onClose">
      <div class="my-5">
        <v-combobox
          :label="labelShareIn"
          outlined
          class="justify-end rounded-lg"
          chips
          clearable
          multiple
          deletable-chips
          :hide-details="true"
          v-model="userShareList"
          :delimiters="[', ', ',', ';', ' ']"
        >
          <template #append>
            <v-btn
              :icon="$vuetify.breakpoint.smAndDown"
              color="primary"
              :loading="loadingShare"
              :disabled="!userShareList[0]"
              class="share-button"
              depressed
              @click="shareItems"
            >
              <v-icon v-if="$vuetify.breakpoint.smAndDown">
                mdi-send
              </v-icon>
              <span v-else> {{ $t('content.share_now') }}</span>
            </v-btn>
          </template>
        </v-combobox>
      </div>

      <v-list nav class="px-0">
        <v-list-item v-for="item in userList" :key="item.id" class="custom-list mb-4">
          <v-list-item-content>
            <v-list-item-title class="title" v-text="item.address" />
          </v-list-item-content>
          <v-list-item-action>
            <BaseActionButton
              :disabled="loadingShare"
              :tooltipsText="$t('content.share')"
              :selectedItem="item"
              @click="shareItem(item.address)"
            >
              <SvgIcon icon="icon-share" width="20px" height="20px" />
            </BaseActionButton>
          </v-list-item-action>
          <v-list-item-action>
            <BaseActionButton
              :disabled="loadingShare == item"
              :tooltipsText="$t('content.delete')"
              v-if="item.deletable"
              @click="removeOwner(item.id)"
            >
              <SvgIcon icon="icon-delete" width="20px" height="20px" />
            </BaseActionButton>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </BaseDialog>

    <v-dialog max-width="200px" v-model="loadingShare">
      <v-card flat class="pa-4 text-center">
        <v-progress-circular v-if="loadingShare" :size="100" color="primary-light" indeterminate />
        <div class="mt-4">
          {{ $t('content.please_wait') }}
        </div>
      </v-card>
    </v-dialog>

    <BaseAlert ref="alert" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseActionButton from '@/components/base/BaseActionButton';
import BaseAlert from '@/components/base/BaseAlert';
import BaseDialog from '@/components/base/BaseDialog';

export default {
  name: 'InvoiceShare',
  components: {
    BaseActionButton,
    BaseAlert,
    BaseDialog,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    invoice: {
      type: Object,
      default: () => {},
    },
    itemFileShare: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loadingShare: false,
      successShare: 0,
      userShareList: [],
      userList: [],
    };
  },
  computed: {
    ...mapGetters({
      user: 'Auth/user',
      invoiceDetail: 'Invoice/invoiceDetail',
      invoiceOwners: 'Invoice/invoiceOwners',
      usersDeliverables: 'DeliverableCenter/usersDeliverables',
    }),
    labelShareIn() {
      return this.$vuetify.breakpoint.name !== 'lg'
        ? this.$t('content.share_in').slice(0, 26) + '...'
        : this.$t('content.share_in');
    },
  },
  watch: {
    invoiceOwners() {
      this.userList = this.invoiceOwners.map(work_order => {
        return {
          id: work_order.id,
          address: work_order.owner.address,
          deletable: (this.user.id==work_order['shared_by']||this.user.master_ids.includes(this.invoice.company_id))?true:false
        };
      });
    },
    usersDeliverables() {
      this.userList = this.usersDeliverables.map(deliverable => {
        return { address: deliverable.owner.address };
      });
    },
  },
  methods: {
    async shareItems() {
      if (
        await this.$refs.alert.open({
          type: 'confirm',
          title: `${this.$t('content.share_invoice')}`,
          message: `${this.$t('content.sure')} ${this.$t('content.invoice', {
            invoice: this.invoice.id,
          })}${this.userShareList.length === 1 ? ' ' + this.$t('content.to') + ' ' + this.userShareList[0] : ''}`,
          cancelBtn: true,
          icon: 'send',
        })
      ) {
        this.loadingShare = true;
        for (let i = 0; i < this.userShareList.length; i++) {
          await this.createInvoiceOwner(this.userShareList[i]);
        }
        if (this.successShare > 0) {
          this.$refs.alert.open({
            type: 'confirm',
            title: `${this.$t('form.success')}`,
            message: `${this.$t('form.congrats')} ${this.$t('content.invoice', {
              invoice: this.invoice.id,
            })}`,
            icon: 'success',
            btnAgreeText: 'OK',
          });
        }
        this.reFetchOwner();
        this.successShare = 0;
        this.userShareList = [];
        this.loadingShare = false;
      }
    },
    shareItem(address) {
      if (this.userShareList.length > 0) this.userShareList = [];
      this.userShareList.push(address);
      this.shareItems();
    },
    async createInvoiceOwner(address) {
      let action = 'Invoice/createInvoiceOwner';
      const owner = { address };
      const payload = {
        object_id: this.invoice.id,
        owner,
        shared_by: this.user.id,
      };

      if (this.invoice?.contentTypeId) {
        payload.content_type = this.invoice?.contentTypeId;
        action = 'DeliverableCenter/createDeliverableOwner';
      }

      await this.$store
        .dispatch(action, payload)
        .then(() => {
          this.successShare++;
          this.$toast.success(`${this.$t('content.success_share')} ${payload.owner.address}`);
        })
        .catch(err => this.$toast.error(`${payload.owner.address}. ` + err.message))
        .finally(() => (this.loadingShare = false));
    },
    reFetchOwner() {
      let action = 'Invoice/getInvoiceOwner';
      let param = this.invoice.id;
      if (this.invoice?.contentTypeId) {
        action = 'DeliverableCenter/fetchUsersDeliverable';
        param = {
          object_id: this.invoice.id,
          content_type: this.invoice?.contentTypeId,
        };
      }

      this.$store
        .dispatch(action, param)
        .then(() => (this.dialogShare = true))
        .catch(err => this.$toast.error(err.message));
    },

    getName(url) {
      return decodeURIComponent(url.substring(url.lastIndexOf('/') + 1));
    },
    onClose() {
      this.$emit('close');
      this.userShareList = [];
      setTimeout(() => {
        this.$store.commit('Invoice/RESET_INVOICE_OWNERS');
        this.$store.commit('DeliverableCenter/RESET_USERS_DELIVERABLES');
      }, 300);
    },
    async removeOwner(itemID){
      if (
        await this.$refs.alert.open({
          type: 'confirm',
          title: `${this.$t('content.unshare_title')}`,
          message: `${this.$t('content.unshare_message')} `,
          cancelBtn: true,
          btnAgreeText: `${this.$t('content.revoke')}`,
          icon: 'delete',
        })
      ){
        const payload = {
          pk: itemID,
        }
        this.$store
          .dispatch('Invoice/removeInvoiceOwner', payload)
          .then(()=>{
            this.reFetchOwner()
          })
          .catch(error => this.$store.dispatch('exceptionHandler/error', error));
        }
    },
  },
};
</script>

<style lang="scss" scoped>
.share-button {
  text-transform: none;
  margin-top: -8 px;
}

::v-deep .v-input__append-inner {
  margin-top: 14px;

  .v-input__icon--clear {
    padding-top: 16px;
  }
}

::v-deep .v-select__slot {
  .v-label {
    top: 25px;
    font-size: 14px;
  }

  .v-label--active {
    transform: translateY(-31px) scale(0.75);
  }
}

::v-deep .v-select__selections {
  .v-chip {
    background-color: #dfecf6 !important;
    color: #0060af !important;
    border-radius: 4px;

    .v-chip__content {
      .v-icon {
        color: #0060af !important;
      }
    }
  }
}
</style>
