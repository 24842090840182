<template>
  <div>
    <v-row v-if="invoicesSelected.length > 0 && isUnpaid" no-gutters align="center" justify="end">
      <v-col cols="auto" class="mb-4 mb-md-0">
        <v-chip class="mr-3" color="warning" small outlined>
          {{ $t('content.invoices_total_amount', { totalAmount }) }}
        </v-chip>
      </v-col>
      <v-col cols="12" md="auto" class="mb-2 mb-md-0">
        <v-btn
          v-if="isUsaClient"
          small
          depressed
          class="orange white--text mr-md-3"
          :block="$vuetify.breakpoint.smAndDown"
          @click="navigateToPay()"
        >
          {{ $t('content.invoices_total_pay_with_card') }}
        </v-btn>
      </v-col>
    </v-row>

    <!-- Test Orders - Filter (for mobile view only) -->
    <section class="d-block d-sm-none mt-5">
      <div class="mt-3" v-if="filteredHeaderMobile('invoice_number')">
        <v-text-field
          outlined
          dense
          v-model="filter.invoiceNumber"
          :placeholder="`${$t('content.search_invoice_number')}...`"
          single-line
          class="rounded-lg  mt-2"
          :hide-details="true"
          clearable
          background-color="#fff"
        />
      </div>
      <div class="mb-3" v-if="filteredHeaderMobile('company')">
        <v-autocomplete
          v-model="filter.company"
          outlined
          dense
          single-line
          hide-details
          background-color="#fff"
          class="rounded-lg  mt-2"
          clearable
          :placeholder="`${$t('content.select_company')}`"
          :items="companies"
          item-text="name"
          item-value="id"
        />
      </div>
      <div class="mb-3" v-if="filteredHeaderMobile('invoice_work_orders')">
        <v-text-field
          outlined
          dense
          v-model="filter.idflNumber"
          :placeholder="`${$t('content.search_invoice_work_orders')}...`"
          single-line
          class="rounded-lg  mt-2"
          :hide-details="true"
          clearable
          background-color="#fff"
        />
      </div>
      <div class="mb-3" v-if="filteredHeaderMobile('due_date')">
        <date-picker
          v-model="filter.dueDate"
          format="YYYY-MM-DD"
          range
          value-type="format"
          :clearable="false"
          style="width: 100%"
        >
          <i slot="icon-calendar"></i>
          <template #input>
            <v-text-field
              outlined
              dense
              single-line
              :placeholder="$t('content.select_due_date')"
              readonly
              v-model="filter.dueDate"
              :hide-details="true"
              class="rounded-lg"
              background-color="#fff"
              clearable
            >
              <svg-icon icon="icon-calendar" slot="append" class="mt-1" />
            </v-text-field>
          </template>
        </date-picker>
      </div>
      <div class="mb-3" v-if="filteredHeaderMobile('currency')">
        <v-autocomplete
          v-model="filter.currency"
          outlined
          dense
          single-line
          hide-details
          background-color="#fff"
          class="rounded-lg  mt-2"
          clearable
          :placeholder="`${$t('content.select')} Currency`"
          :items="currencies"
          item-text="currency"
          item-value="currency"
        />
      </div>
      <div class="mb-3" v-if="filteredHeaderMobile('fully_paid_on')">
        <date-picker
          v-model="filter.dateInvoiced"
          format="YYYY-MM-DD"
          range
          value-type="format"
          :clearable="false"
          style="width: 100%"
        >
          <i slot="icon-calendar"></i>
          <template #input>
            <v-text-field
              outlined
              dense
              single-line
              :placeholder="$t('content.select_payment_date')"
              readonly
              v-model="filter.dateInvoiced"
              :hide-details="true"
              class="rounded-lg"
              background-color="#fff"
              clearable
            >
              <svg-icon icon="icon-calendar" slot="append" class="mt-1" />
            </v-text-field>
          </template>
        </date-picker>
      </div>
      <div class="mb-3" v-if="filteredHeaderMobile('select_to_pay')">
        <div class="d-flex align-center justify-space-between">
        {{ $t('content.invoices_total_pay_with_card') }}
         
          <v-simple-checkbox
            color="primary"
            :value="selectAll"
            :disabled="selectAllDisabled"
            :ripple="false"
            @input="onChangeSelectAll"
          />
        </div>
      </div>
    </section>

    <v-data-table
      dense
      hide-default-footer
      :loading="loading"
      :headers="filteredHeader"
      :items="invoices.results"
      :server-items-length="invoices.count"
      item-key="id"
      class="custom-table empty-with-dash"
    >
      <template #[`header.invoice_number`]="{ header }">
        {{ header.text }}
        <div class="mt-2">
          <v-text-field
            outlined
            dense
            v-model="filter.invoiceNumber"
            :placeholder="`${$t('content.search_invoice_number')}...`"
            single-line
            class="rounded-lg  mt-2"
            :hide-details="true"
            clearable
            background-color="#fff"
          />
        </div>
      </template>
      <template #[`header.company`]="{ header }">
        <div class="mt-0">
          {{ header.text }}
          <div class="mt-2" style="max-width:198px;">
            <v-autocomplete
              v-model="filter.company"
              outlined
              dense
              single-line
              hide-details
              background-color="#fff"
              class="rounded-lg  mt-2"
              clearable
              :placeholder="`${$t('content.select_company')}`"
              :items="companies"
              item-text="name"
              item-value="id"
            />
          </div>
        </div>
      </template>
      <template #[`header.invoice_work_orders`]="{ header }">
        {{ header.text }}
        <div class="mt-2">
          <v-text-field
            outlined
            dense
            v-model="filter.idflNumber"
            :placeholder="`${$t('content.search_invoice_work_orders')}...`"
            single-line
            class="rounded-lg  mt-2"
            :hide-details="true"
            clearable
            background-color="#fff"
          />
        </div>
      </template>
      <template #[`header.fully_paid_on`]="{ header }">
        {{ header.text }}
        <date-picker v-model="filter.dateInvoiced" format="YYYY-MM-DD" range value-type="format" :clearable="false" class="mt-2">
          <i slot="icon-calendar"></i>
          <template #input>
            <v-text-field
              outlined
              dense
              single-line
              :placeholder="$t('content.select_date')"
              readonly
              v-model="filter.dateInvoiced"
              :hide-details="true"
              class="rounded-lg"
              background-color="#fff"
              clearable
            >
              <svg-icon icon="icon-calendar" slot="append" class="mt-1" />
            </v-text-field>
          </template>
        </date-picker>
      </template>
      <template #[`header.currency`]="{ header }">
        {{ header.text }}
        <div class="mt-2">
          <v-autocomplete
            v-model="filter.currency"
            outlined
            dense
            single-line
            hide-details
            background-color="#fff"
            class="rounded-lg  mt-2"
            clearable
            :placeholder="`${$t('content.select')}`"
            :items="currencies"
            item-text="currency"
            item-value="currency"
          />
        </div>
      </template>
      <template #[`header.due_date`]="{ header }">
        {{ header.text }}
        <date-picker v-model="filter.dueDate" format="YYYY-MM-DD" range value-type="format" :clearable="false" class="mt-2">
          <i slot="icon-calendar"></i>
          <template #input>
            <v-text-field
              outlined
              dense
              single-line
              :placeholder="$t('content.select_date')"
              readonly
              v-model="filter.dueDate"
              :hide-details="true"
              class="rounded-lg"
              background-color="#fff"
              clearable
            >
              <svg-icon icon="icon-calendar" slot="append" class="mt-1" />
            </v-text-field>
          </template>
        </date-picker>
      </template>
      <template #[`header.select_to_pay`]="{ header }">
        <p class="d-flex align-center justify-center">
          {{ header.text }}
        </p>
        <div class="d-flex align-center justify-center" style="max-height:15px;">
          <v-checkbox color="primary" :value="selectAll" :disabled="selectAllDisabled" @change="onChangeSelectAll" />
        </div>
      </template>

      <template #[`item.invoice_work_orders`]="{ value }">
        <v-expansion-panels accordion flat v-if="value.length > 1" class="expansion-title">
          <v-expansion-panel class="expansion-panel">
            <v-expansion-panel-header class="pl-0">
              {{ value[0].invoice_number }}
              <template #actions>
                <v-icon color="blue lighten-1">
                  mdi-menu-down
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pl-0 " v-for="(item, index) in value.slice(1)" :key="index">
              {{ item.invoice_number }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels accordion flat v-else>
          <v-expansion-panel class="expansion-panel">
            <v-expansion-panel-header hide-actions class="px-0">
              {{ value[0].invoice_number }}
            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
      <template #[`item.fully_paid_on`]="{ value }">
        {{ utilityMixin_formatDateTime(value) }}
      </template>
      <template #[`item.currency`]="{ value }">
        {{ value }}
      </template>
      <template #[`item.due_date`]="{ value }">
        {{ utilityMixin_formatDateTime(value) }}
      </template>
      <template #[`item.amount_due`]="{ item }">
        <div :class="['font-weight-black', pastDue(item.due_date) && isUnpaid ? 'red--text' : 'green--text']">
          <v-row align="center">
            <InvoicePopper :item="item" :isUnpaid="isUnpaid" />
            {{ item.amount_due }}
          </v-row>
        </div>
      </template>
      <template #[`item.amount_paid`]="{ item }">
        <div :class="['font-weight-black', pastDue(item.due_date) && isUnpaid ? 'red--text' : 'green--text']">
          <v-row align="center">
            <InvoicePopper :item="item" :isUnpaid="isUnpaid" />
            {{ item.amount_paid }}
        </v-row>
        </div>
      </template>
      <template #[`item.net_total`]="{ item }">
        {{ item.net_total }}
      </template>
      <template #[`item.select_to_pay`]="{ item }">
        <div class="d-flex align-center justify-center">
          <v-checkbox
            v-if="item.currency === 'USD'"
            class="mt-0"
            color="primary"
            hide-details
            v-model="invoicesSelectedLocal"
            :disabled="currencySelected(item.currency)"
            :value="item.id"
            :value-comparator="checkboxValueComparator"
            @change="onChangeSelected(item, $event)"
          />
        </div>
      </template>
      <template #[`item.actions`]="{ item }">
        <BaseActionButton :tooltipsText="$t('content.view_invoice')" @click="onViewDocument(item)">
          <SvgIcon icon="icon-eye" width="20px" height="20px" />
        </BaseActionButton>
        <BaseActionButton :tooltipsText="$t('content.share')" @click="onShareInvoice(item)">
          <SvgIcon icon="icon-share" width="20px" height="20px" />
        </BaseActionButton>
      </template>

      <template #no-data>
        <BaseNoData :message="$t('content.no_invoice_data')" />
      </template>
      <template #footer>
        <BaseTableFooter :data="invoices" :filter="filter" :params="table.params" @change="onChangeTable" />
      </template>
    </v-data-table>
    <!-- #endregion -->

    <!-- #region dialog -->
    <InvoiceShare :dialog="dialogShare" :invoice="selectedItemShare" @close="onCloseDialogShare" />

    <InvoiceDialogFileList
      v-model="dialogFileList"
      :items="invoiceFiles"
      :invoiceNumberDialog="invoiceNumberDialog"
      @showFile="onShowFile"
      @shareInvoice="onShareInvoice"
      @close="onCloseDialogFileList"
    />

    <InvoiceDialogTransferLanguage v-model="dialogTransferLanguage" @click="onShowTransferInfo" />

    <DocumentViewer
      :dialog="dialogViewDocument"
      :item.sync="selectedItemViewer"
      title="Invoice File"
      @close="onCloseDialogViewer"
    />

    <BaseAlert ref="alert" />
    <!-- #endregion -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DatePicker from 'vue2-datepicker';
import { utilityMixin, downloadMixin } from '@/mixins';
import InvoiceShare from '@/components/invoice/InvoiceShare';
import debounce from 'lodash/debounce';

import moment from 'moment-timezone';
import InvoiceDialogTransferLanguage from '@/components/invoice/InvoiceDialogTransferLanguage';
import InvoiceDialogFileList from '@/components/invoice/InvoiceDialogFileList';
import InvoicePopper from '@/components/invoice/InvoicePopper';
import DocumentViewer from '@/components/dialog/DocumentViewer';

export default {
  name: 'InvoiceList',
  mixins: [utilityMixin, downloadMixin],
  components: {
    DatePicker,
    InvoiceShare,
    InvoiceDialogTransferLanguage,
    InvoiceDialogFileList,
    DocumentViewer,
    InvoicePopper,
  },
  props: {
    initialStatus: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      filter: {
        idflNumber: null,
        company: null,
        dateInvoiced: [],
        dueDate: [],
        currency: '',
        amountDue: null,
        amountPaid: null,
        netTotal: null,
        overallInvoicePaymentsStatus: null,
        stage: 'All Status',
      },
      table: {
        headers: [
          { text: this.$t('content.invoice_number'), value: 'invoice_number', width: 230, sortable: false },
          { text: this.$t('content.payer_company'), value: 'company', width: 230, sortable: false },
          { text: this.$t('content.invoice_work_orders'), value: 'invoice_work_orders', width: 230, sortable: false },
          { text: this.$t('content.due_date'), value: 'due_date', width: 180, sortable: false },
          { text: this.$t('content.payment_date'), value: 'fully_paid_on', width: 180, sortable: false },
          { text: this.$t('content.currency'), value: 'currency', width: 158, sortable: false },
          { text: this.$t('content.net_total'), value: 'net_total', sortable: false, width: 200 },
          { text: this.$t('content.amount_paid'), value: 'amount_paid', sortable: false, width: 200 },
          { text: this.$t('content.amount_due'), value: 'amount_due', width: 200, sortable: false, align: ' center' },
          { text: this.$t('content.select_to_pay'), value: 'select_to_pay', sortable: false, width: 150, align: ' center' },
          { text: this.$t('content.actions'), value: 'actions', sortable: false, width: 160 },
        ],
        params: {
          limit: 10,
          stage: 'All Status',
        },
        hide: {
          unpaid: ['fully_paid_on', 'amount_paid'],
          paid: ['amount_due', 'net_total', 'select_to_pay', 'overall_invoice_payments_status'],
        },
      },
      initial: true,
      loading: false,
      dialogShare: false,
      dialogFileList: false,
      dialogViewDocument: false,
      dialogTransferInfo: false,
      invoiceFiles: [],
      invoicesSelectedLocal: [],
      invoiceNumberDialog: '',
      selectedItemShare: {},
      selectedItemViewer: {},
      dialogTransferLanguage: false,
      currency: '',
    };
  },
  computed: {
    ...mapGetters({
      invoicesPaid: 'Invoice/invoicesPaid',
      invoicesUnpaid: 'Invoice/invoicesUnpaid',
      companiesPaid: 'Invoice/companiesPaid',
      companiesUnpaid: 'Invoice/companiesUnpaid',
      currenciesPaid: 'Invoice/currenciesPaid',
      currenciesUnpaid: 'Invoice/currenciesUnpaid',
      invoicesSelected: 'Invoice/invoicesSelected',
    }),
    isUsaClient() {
      return this.currency === '' ? false : this.currency === 'USD';
    },
    isUnpaid() {
      return this.initialStatus !== 'Fully Paid';
    },
    filteredHeader() {
      if (this.isUnpaid) {
        return this.table.headers.filter(h => !this.table.hide.unpaid.includes(h.value));
      } else {
        return this.table.headers.filter(h => !this.table.hide.paid.includes(h.value));
      }
    },
    invoices() {
      if (this.isUnpaid) {
        return this.invoicesUnpaid;
      } else {
        return this.invoicesPaid;
      }
    },
    companies() {
      if (this.isUnpaid) {
        return this.companiesUnpaid;
      } else {
        return this.companiesPaid;
      }
    },
    currencies() {
      if (this.isUnpaid) {
        return this.currenciesUnpaid;
      } else {
        return this.currenciesPaid;
      }
    },
    dateInvoicedValue() {
      return this.utilityMixin_splitDate(this.filter.dateInvoiced, 'fully_paid_on_after', 'fully_paid_on_before');
    },
    dueDateValue() {
      return this.utilityMixin_splitDate(this.filter.dueDate, 'due_date_after', 'due_date_before');
    },
    invoicesFiltered() {
      return this.invoices.results.filter(
        iv => iv.overall_invoice_payments_status !== 'Fully Paid' && iv.currency == this.currency,
      );
    },
    selectAll() {
      const invoices = this.invoicesSelected.filter(invoice => this.invoicesFiltered.some(iv => iv.id === invoice.id));
      return (
        this.invoicesFiltered.length > 0 && this.invoicesFiltered.length === invoices.length && this.filter.stage !== 'Fully Paid'
      );
    },
    selectAllDisabled() {
      return this.filter.stage === 'Fully Paid' || this.invoicesFiltered.length <= 0;
    },
    totalAmount() {
      var totalsWithoutNull = this.invoicesSelected.filter(n => n)
      const total = totalsWithoutNull.reduce(
        (acc, row) => ((acc += +row.amount_due.replace(/^\D+/g, '').replace(/,/gi, '')), acc),
        0,
      );
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: this.currency }).format(total);
    },
  },
  watch: {
    filter: {
      deep: true,
      handler: debounce(function(filter) {
        this.onChangeTable({
          ...filter,
          ...(!this.initial ? { offset: 0, page: 1 } : null),
        });
      }, 300),
    },
    invoicesSelected: {
      deep: true,
      handler(newSelected) {
        if (newSelected.length == 1) {
          this.currency = newSelected[0].currency;
        } else if (newSelected.length == 0) {
          this.currency = '';
        }
        this.invoicesSelectedLocal = newSelected;
      },
    },
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.filter.stage = this.initialStatus;
      if (this.isUnpaid) {
        this.$store.commit('Invoice/RESET_INVOICES_UNPAID');
      } else {
        this.$store.commit('Invoice/RESET_INVOICES_PAID');
      }

      const query = {
        ...this.table.params,
        ...this.utilityMixin_mappingValue(this.filter, {
          netTotal: 'net_total',
          amountPaid: 'amount_paid',
          amountDue: 'amount_due',
          invoiceNumber: 'invoice_number',
          idflNumber: 'work_order',
        }),
        ...this.dateInvoicedValue,
        ...this.dueDateValue,
      };

      this.utilityMixin_deleteKeys(query, [
        'overallInvoicePaymentsStatus',
        'dueDate',
        'dateInvoiced',
        'amountDue',
        'amountPaid',
        'netTotal',
        'idflNumber',
        'invoiceNumber',
        'stage',
      ]);

      const params = {
        status: this.isUnpaid ? 'unpaid' : 'paid',
        query,
      };

      this.$store
        .dispatch('Invoice/getInvoices', params)
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          this.loading = false;
          this.initial = false;
        });
    },
    onChangeTable(params = {}) {
      this.table.params = {
        ...this.table.params,
        ...params,
      };
      this.fetchData();
    },
    onShareInvoice(val) {
      let action = 'Invoice/getInvoiceOwner';
      let param = val.id;

      if (val?.contentTypeId) {
        action = 'DeliverableCenter/fetchUsersDeliverable';
        param = {
          object_id: val.id,
          content_type: val?.contentTypeId,
        };
      }

      this.loading = true;
      this.selectedItemShare = val;
      this.selectedItemShare.name = this.selectedItemShare.name ?? `Share Invoice ${val.invoice_number}`;

      this.$store
        .dispatch(action, param)
        .then(() => (this.dialogShare = true))
        .catch(err => this.$toast.error(err.message))
        .finally(() => (this.loading = false));
    },
    onCloseDialogShare() {
      this.dialogShare = false;
      this.selectedItemShare = {};
    },
    onCloseDialogViewer() {
      this.dialogViewDocument = false;
      this.selectedItemViewer = {};
    },
    onCloseDialogFileList() {
      this.dialogFileList = false;
      this.invoiceFiles = [];
    },
    onViewDocument(item) {
      this.$store
        .dispatch('Invoice/getInvoiceFiles', item.id)
        .then(files => {
          this.invoiceFiles = files;
          this.invoiceNumberDialog = item.invoice_number;

          this.dialogFileList = true;
        })
        .catch(err => this.$toast.error(err.message));
    },
    onChangeSelectAll(checked) {
      if (checked) {
        this.$store.commit('Invoice/SET_INVOICES_SELECTED', this.invoicesFiltered);
      } else {
        const invoices = this.invoicesSelected.filter(invoice => !this.invoicesFiltered.some(iv => iv.id === invoice.id));
        this.$store.commit('Invoice/UPDATE_INVOICES_SELECTED', invoices);
      }
    },
    onChangeSelected(item) {
      const index = this.invoicesSelected.findIndex(invoice => invoice.id === item.id);
      if (~index) {
        this.$store.commit('Invoice/REMOVE_INVOICES_SELECTED', index);
      } else {
        this.$store.commit('Invoice/SET_INVOICES_SELECTED', [item]);
      }
    },
    onShowTransferLanguage() {
      this.dialogTransferLanguage = true;
    },
    onShowTransferInfo() {
      this.dialogTransferLanguage = false;
      // this.dialogTransferInfo = true;
    },
    onShowFile(file) {
      this.dialogViewDocument = true;
      this.selectedItemViewer = {
        url: file.file_url,
        name: this.getName(file.file_url),
      };
    },
    getName(url) {
      return decodeURIComponent(url.substring(url.lastIndexOf('/') + 1));
    },
    checkboxValueComparator(_, value) {
      return this.invoicesSelected.map(inv => inv.id).includes(value);
    },
    pastDue(dueDateValue) {
      var given = moment(dueDateValue, 'YYYY-MM-DD');
      var current = moment().startOf('day');
      return moment.duration(given.diff(current)).asDays() < 0 ? this.$t('content.past_due') : '';
    },
    currencySelected(currency) {
      return this.currency == '' ? false : currency !== this.currency;
    },
    filteredHeaderMobile(header) {
      let res = null;
      if (this.isUnpaid) {
        res = this.table.hide.unpaid.find(function(head) {
          return head == header;
        });
      } else {
        res = this.table.hide.paid.find(function(head) {
          return head == header;
        });
      }
      return res ? false : true;
    },
    navigateToPay(){
      this.$router.push({
        name: 'PayInvoice'
      });
    },
  },
  created() {
    this.$store.commit('Invoice/RESET_INVOICES_SELECTED');
    this.$store.dispatch('Invoice/getCompanies', this.isUnpaid ? 'unpaid' : 'paid');
    this.$store.dispatch('Invoice/getCurrencies', this.isUnpaid ? 'unpaid' : 'paid');
    this.fetchData();
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.05s;
  opacity: 1;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  visibility: hidden;
}
</style>
