<template>
  <div>
    <v-container>
      <form v-on:submit.prevent="submitForm" id="payment-form">
        <h1>{{ $t('content.pay_invoice_head') }}</h1>
        <v-row class="text-lg-h6">
          <v-col md="6">{{ $t('content.company') }}</v-col>
          <v-col md="3">{{ $t('title.invoice') }}</v-col>
          <v-col md="3">{{ $t('content.amount') }}</v-col>
        </v-row>
        <v-row v-for="(item, index) in invoices" :key="index" style="border-bottom:1px solid #71abdb">
          <v-col md="6">{{ item.company }}</v-col>
          <v-col md="3">
            <v-row align="center">
              {{ item.invoice_number }}
              <InvoicePopper :item="item" />
            </v-row>
          </v-col>
          <v-col md="2">
            <v-text-field
              outlined
              dense
              v-model="item.amount_due"
              v-if="item.editing === true"
              single-line
              class="rounded-lg mt-2"
              background-color="#fff"
            />
            <span v-if="item.editing !== true">{{ item.amount_due }}</span>
          </v-col>
          <v-col md="1">
            <BaseActionButton :tooltipsText="$t('content.edit_payment_amount')" @click="toggleEdit(item)">
              <SvgIcon icon="icon-edit" width="20px" height="20px" />
            </BaseActionButton>
          </v-col>
        </v-row>
        <v-row class="text-lg-h6">
          <v-col md="6"></v-col>
          <v-col md="3">{{ $t('content.total') }}: </v-col>
          <v-col md="3">{{ total }}</v-col>
        </v-row>
        <v-row>
          <v-col md="3">{{ $t('content.payment_method') }}: </v-col>
          <v-col md="9">
            <v-select
              outlined
              dense
              v-model="payForm.selectAccount"
              single-line
              :items="paySimpleAccounts[0].card_info"
              :item-text="getPaymentName"
              :item-value="getPaymentValue"
              hide-details
              :placeholder="$t('form.select_payment_method')"
              background-color="#fff"
              class="rounded-sm mr-3"
              :no-data-text="$t('form.no_payment_method')"
            />
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col md="3">{{ $t('content.from_company') }}: </v-col>
          <v-col md="9">
            <v-select
              outlined
              dense
              v-model="payForm.selectedCompany"
              single-line
              :items="corporate"
              item-text="company"
              item-value="id"
              hide-details
              :placeholder="$t('form.select_company')"
              background-color="#fff"
              class="rounded-sm mr-3"
              :no-data-text="$t('form.no_verified_companies')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-btn
            small
            depressed
            class="orange white--text mr-md-3"
            :block="$vuetify.breakpoint.smAndDown"
            @click="$router.push({ name: 'PaymentMethods' })()"
          >
            {{ $t('content.manage_accounts') }}
          </v-btn>
          <v-btn
            small
            depressed
            class="orange white--text mr-md-3"
            :block="$vuetify.breakpoint.smAndDown"
            @click="backToInvoices()"
          >
            Back to invoices
          </v-btn>
          <v-btn
            small
            depressed
            class="indigo white--text mr-md-3"
            :block="$vuetify.breakpoint.smAndDown"
            :disabled="$v.payForm.$invalid"
            @click="submitPayment()"
          >
            {{ $t('content.invoices_total_pay_with_card') }}
          </v-btn>
        </v-row>
      </form>
    </v-container>
    <BaseAlert ref="alert" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { required, numeric } from 'vuelidate/lib/validators';
import InvoicePopper from '@/components/invoice/InvoicePopper';

var accountValidate = function(value) {
  var checkValue = value.split(':')[0];
  return !(checkValue === 'NewACH' || checkValue === 'NewCard');
};

export default {
  name: 'PayInvoice',
  components: {
    InvoicePopper,
  },
  validations: function() {
    return {
      ammountDue: {
        required,
        numeric,
      },
      payForm: {
        selectAccount: {
          required,
          accountValidate,
        },
      },
    };
  },
  data() {
    return {
      corporate: [{}],
      paySimpleAccounts: [{}],
      payForm: {
        selectAccount: '',
        selectedCompany: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      invoicesSelected: 'Invoice/invoicesSelected',
      paysimpleInfo: 'Invoice/paysimpleInfo',
      masterList: 'Account/listMaster',
    }),
    invoices() {
      return this.invoicesSelected;
    },
    total() {
      const total = this.invoicesSelected.reduce(
        (acc, row) => ((acc += +row.amount_due.replace(/^\D+/g, '').replace(/,/gi, '')), acc),
        0,
      );
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total);
    },
  },
  methods: {
    async submitPayment() {
      this.$v.$touch();
      if (
        await this.$refs.alert.open({
          type: 'confirm',
          title: `${this.$t('title.confirm_pay_title')}`,
          message: `${this.$t('content.confirm_pay_message')}`,
          cancelBtn: true,
          btnAgreeText: `${this.$t('content.yes')}`,
          icon: 'send',
        })
      ) {
        var invoice_info = [];
        for (var invoice in this.invoicesSelected) {
          var invoice_amount = this.invoicesSelected[invoice].amount_due;
          var invoice_number = this.invoicesSelected[invoice].invoice_number;
          invoice_info.push({
            'invoice_amount':invoice_amount,
            'invoice_number':invoice_number,
          });
        }
        var payForm_submit = {
          "selectAccount": this.payForm.selectAccount,
          "selectedCompany": this.payForm.selectedCompany,
          "total":this.total,
          "invoiceInfo":invoice_info
        };
        this.$store
          .dispatch('Invoice/postPaymentData', payForm_submit)
          .then(() => {
            this.$router.push({
              name: 'Invoice',
            });
          })
          .catch(error => this.$store.dispatch('exceptionHandler/error', error));
      }
    },
    getPaymentName(item) {
      if ('BankName' in item) {
        return item.BankName + ' (' + item.AccountNumber + ')';
      }
      return item.Issuer + ' (' + item.CreditCardNumber + ')';
    },
    getPaymentValue(item) {
      if ('BankName' in item) {
        return item.Id + ':ACH';
      }
      return item.Id + ':Card';
    },
    toggleEdit(item) {
      if (item.editing !== true) {
        this.$set(item, 'editing', true);
      } else {
        this.$set(item, 'editing', false);
      }
    },
    fetchCompany() {
      const profileId = this.$store.getters['Auth/user'].customer_user_profile_id;
      this.$store
        .dispatch('Account/getCompany', profileId)
        .then(data => {
          this.corporate = data;
          for (var a in this.corporate) {
            if (this.corporate[a].is_master) {
              this.payForm.selectedCompany = this.corporate[a].id;
              return;
            }
          }
          this.payForm.selectedCompany = this.corporate[0].id;
        })
        .catch(err => this.$toast.error(err.message));
    },
    fetchPaysimple() {
      this.$store
        .dispatch('Invoice/getPaysimpleInfo')
        .then(data => {
          if (data[0].card_info === null) {
            data[0].card_info = [];
          }
          this.paySimpleAccounts = data;
          this.paySimpleAccounts[0].card_info.push({
            "BankName":`${this.$t("content.new_bank_account")}`,
            "AccountNumber":`${this.$t("content.enter_info")}`,
            "Id":"NewACH",
          });
          this.paySimpleAccounts[0].card_info.push({
            "BankName":`${this.$t("content.new_credit_card")}`,
            "AccountNumber":`${this.$t("content.enter_info")}`,
            "Id":"NewCard",
          });
          for (var account in this.paySimpleAccounts[0].card_info) {
            if (this.paySimpleAccounts[0].card_info[account].IsDefault === true) {
              this.payForm.selectAccount = this.getPaymentValue(this.paySimpleAccounts[0].card_info[account]);
              return;
            }
          }
          this.payForm.selectAccount = this.getPaymentValue(this.paySimpleAccounts[0].card_info[0]);
        })
        .catch(err => this.$toast.error(err.message));
    },
    backToInvoices() {
      this.$router.push({ name: 'Invoice' });
    },
  },
  created() {
    this.fetchCompany();
    this.fetchPaysimple();
  },
};
</script>

<style>
.col {
  vertical-align: middle;
}
#payment-form {
  width: 65%;
  border-radius: 5px;
  background-color: white;
  padding: 50px;
  margin: 0 auto;
  box-shadow: 0 2px 10px rgb(0 0 0 / 30%);
}
#payment-form > h1 {
  background-color: #71abdb;
  color: white;
  padding: 15px;
  margin: -50px -50px 40px;
}
</style>
