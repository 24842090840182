<template>
  <div>
    <Popper
      trigger="hover"
      :options="{ placement: 'top' }"
      transition="fade"
      enter-active-class="fade-enter-active"
      leave-active-class="fade-leave-active"
    >
      <v-btn icon slot="reference">
        <SvgIcon icon="icon-info-blue" />
      </v-btn>
      <div class="popper extra-popover without-arrow">
        <h1>
          <strong>
            {{ pastDue(item.due_date) != '' && isUnpaid ? pastDue(item.due_date) : $t('content.total_amount_due_info') }}
          </strong>
        </h1>
        <div class="pa-5 t-navy">
          <strong>
            {{ $t('content.total_amount_due_info') + ': ' + item.amount_due }}
          </strong>

          <div class="ml-2 mt-2">
            <p class="mr-3 t-navy">
              {{ '- ' + $t('content.invoiced_amount') + ' : ' + item.net_total }}
            </p>
          </div>
          <div class="ml-2 mt-2">
            <p class="mr-3 t-navy">
              {{ '- ' + $t('content.credit_amount') + ' : ' + item.credit_amount }}
            </p>
          </div>
          <div class="ml-2 mt-4">
            <p class="mr-3 t-navy">
              {{ '- ' + $t('content.amount_paid') + ' : ' + item.amount_paid }}
            </p>
          </div>
          <div class="ml-2 mt-2">
            <p class="mr-3 t-navy">
              {{ '- ' + $t('content.late_fee') + ' : ' + item.additional_fee_for_being_late }}
            </p>
          </div>
          <div class="ml-2 mt-2">
            <p class="mr-3 t-navy">
              {{ '- ' + $t('content.discount_removed') + ' : ' + item.discount_forfeited }}
            </p>
          </div>
        </div>
      </div>
    </Popper>
  </div>
</template>

<script>
import moment from 'moment-timezone';

export default {
  name:"InvoicePopper",
  props: {
    item:{
      type: Object,
      default: () => {},
    },
    isUnpaid:{
      type:Boolean,
      default:false
    }
  },
  methods:{

    pastDue(dueDateValue) {
      var given = moment(dueDateValue, 'YYYY-MM-DD');
      var current = moment().startOf('day');
      return moment.duration(given.diff(current)).asDays() < 0 ? this.$t('content.past_due') : '';
    },
  },

}
</script>
